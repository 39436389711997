<template>
  <div id="app">
    <headers-nav v-if="$route.path !== '/404'" />
    <router-view></router-view>
    <Footer v-if="$route.path !== '/404'" />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    HeadersNav: () => import('@/components/HeadersNav.vue'),
    Footer: () => import('@/components/Footer.vue')
  },
  data() {
    return {}
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
  position: relative;
  /* 解决轮播图滑动报错 */
  *{touch-action: none;} 
}
</style>
